import "./App.css";
import Finman from "./components/Finman";

function App() {
  return (
    <div className="App">
      <Finman />
    </div>
  );
}

export default App;
