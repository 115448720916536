import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Right from "../../src/static/right.jpg";
import Reset from "../../src/static/reset.png";
import toast, { Toaster } from "react-hot-toast";
import money from "../static/money.gif"
export default function Finman() {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [expenditure, setExpenditure] = useState("");
  const [modeOfPayment, setModeOfPayment] = useState("");
  const [notes, setNotes] = useState("");
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const delay = 500; // 1 second delay
    const timeoutId = setTimeout(() => {
      setShowContent(true);
    }, delay);

    // Cleanup function to clear the timeout in case the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);

  const handleExpenditure = (e) => {
    setExpenditure(e.target.value);
  };

  const handleModeOfPayment = (e) => {
    setModeOfPayment(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      // Collect form data
      const formData = {
        amount,
        expenditure,
        modeOfPayment,
        notes,
      };

      console.log(formData);

      // Submit the form data as JSON
      await fetch(
        "https://script.google.com/macros/s/AKfycbwlM9f8E1oZL7Y6IzXRHjEguVyse97TAH5GzPGwbYR3vVOrqvShivEQ_ciO-t6QEWix/exec",
        {
          method: "POST",
          mode: "no-cors", // Set mode to 'no-cors'
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      console.log(formData);

      // Handle the response
      toast.success("Data recorded Successfully !");
      // Reset the form after successful submission
      setAmount("");
      setExpenditure("");
      setModeOfPayment("");
      setNotes("");
    } catch (error) {
      toast.error("Error submitting form ! Contact Developer.");
      // Handle general error scenario
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    // Clear the form when the "Clear" button is clicked
    setAmount("");
    setExpenditure("");
    setModeOfPayment("");
    setNotes("");
  };

  return (
    <div>
      {showContent && (
        <>
          {/* <CSSTransition
            key={animateContent}
            in={showContent}
            timeout={500}
            classNames="content"
            appear
            onEntered={() => setAnimateContent(false)} // Reset animation trigger
          > */}
          <div className="main">

            <h1 className="heading white">Welcome Back, Shrutika!</h1>
            <div className="container">
              <div className="logo">
                {/* <img src={money} alt="" /> */}
                <iframe src="https://lottie.host/embed/fdea9b52-e686-4895-bad4-6df666e9d168/ZrSTZmgN6L.json"></iframe>
              </div>

              <form>
                <FloatingLabel
                  controlId="amount"
                  label="Amount"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="0000.00"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </FloatingLabel>
                <Form.Select
                  aria-label="Source"
                  size="lg"
                  id="source"
                  value={expenditure}
                  onChange={handleExpenditure}
                >
                  <option>Expenses:</option>
                  <option value="Food">😋 Food</option>
                  <option value="Groceries"> 🥕 Groceries</option>
                  <option value="Transport">🚀 Transport</option>
                  <option value="Other">📄 Other</option>
                </Form.Select>
                &nbsp;
                <Form.Select
                  aria-label="MOPs"
                  id="mop"
                  size="lg"
                  value={modeOfPayment}
                  onChange={handleModeOfPayment}
                >
                  <option>Mode of Payment:</option>
                  <option value="UPI"> 🪙 UPI</option>
                  <option value="CASH"> 💵 CASH</option>
                  <option value="CC"> 💳 CC</option>
                  <option value="Other">💰 Other</option>
                </Form.Select>
                &nbsp;
                <FloatingLabel controlId="notes" label="Notes" className="mb-2">
                  <Form.Control
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    as="textarea"
                    placeholder="Leave a comment here"
                  />
                </FloatingLabel>
                &nbsp;
                <div className="buttons">
                  <Button variant="success" size="lg" onClick={handleSubmit}>
                    {!loading ? (
                      <>
                        SUBMIT &nbsp;
                        <img src={Right} width={"25px"} alt="" />
                      </>
                    ) : (
                      <>
                        Submitting &nbsp;
                        <Spinner size="sm" animation="border" variant="light" />
                      </>
                    )}
                  </Button>
                  <p> </p>

                  <Button variant="danger" size="lg" onClick={handleClear}>
                    CLEAR &nbsp; <img src={Reset} width={"25px"} alt="" />
                  </Button>
                </div>
              </form>
            </div>
            <p className="white">Created with ❣️ for Shrutika Di</p>
            <Toaster />
            </div>

          {/* </CSSTransition> */}
        </>
      )}
  </div>);
}
